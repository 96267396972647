import { useEffect } from "react";
import { useFormikContext } from "formik";

const FocusError = () => {
  const { errors, isSubmitting, isValidating } = useFormikContext();

  useEffect(() => {
    if (isSubmitting && !isValidating) {
      let keys = Object.keys(errors);
      if (keys.length > 0) {
        keys = keys.filter((k) => k !== "additional" && k !== "media");
        const e = errors as any;
        if (e.additional) {
          let additionalKeys = Object.keys(e.additional);
          additionalKeys = additionalKeys.map((a) => `additional.${a}`);
          keys = keys.concat(additionalKeys);
        }
        const selector = `[id="${keys[0]}"]`;
        const errorElement = document.querySelector(selector) as HTMLElement;
        if (errorElement) {
          errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
          errorElement.focus({ preventScroll: true });
        }
      }
    }
  }, [errors, isSubmitting, isValidating]);

  return null;
};

export default FocusError;
