import { Button } from "@appquality/appquality-design-system";
import { useTranslation } from "react-i18next";
import getTranslatableLink from "src/features/getTranslatableLink";
import DashboardHelpStore from "src/redux/dashboardHelpModal";
import { useGetUsersMeCampaignsQuery } from "src/services/tryberApi";
import dateFormatter from "src/utils/dateFormatter";

const useCampaigns = ({ page, order, orderBy }: CampaignsTableProps) => {
  const { t, i18n } = useTranslation();
  const { open } = DashboardHelpStore();

  const limit = 10;
  const { data, isFetching } = useGetUsersMeCampaignsQuery({
    start: (page - 1) * limit,
    limit: limit,
    filterBy: {
      accepted: "1",
      statusId: "1",
      completed: "1",
    },
    order: order,
    orderBy: orderBy,
  });

  const campaigns = data?.results?.map((cp) => {
    const manualLink = getTranslatableLink(cp.manual_link, i18n.language);
    return {
      key: cp.id ? cp.id : 123,
      campaigns: `${cp.id ? `[CP${cp.id}] - ` : ""}${cp.name}`,
      endDate: dateFormatter(cp.dates.end),
      closeDate: dateFormatter(cp.dates.close),
      actions: {
        title: ``,
        content: (
          <Button
            forwardedAs="a"
            disabled={manualLink === "#"}
            className="aq-nopadding"
            href={
              manualLink === "#"
                ? "#"
                : `${window.location.origin}${manualLink}`
            }
            kind="link-hover"
            size="sm"
          >
            {t("Review the manual")}
          </Button>
        ),
      },
    };
  });
  return { data, campaigns, isLoading: isFetching };
};

export default useCampaigns;
